import React, { useState } from "react";
import styles from "../_css/propertyOrUnitCta.module.css";
import { userStore } from "users/_stores/userStore";
import { useTranslation } from "react-i18next";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FormProvider, useForm } from "react-hook-form";
import { PropertyOrUnitContactFormBtn } from "properties/propertyPage/contact-form/property-or-unit-contact-form-btn";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PROPERTY_SALES_TYPE } from "properties/_models/PropertyMdl";
import { CheckboxAgree } from "properties/propertyPage/_components/CheckboxAgree";
import { observer } from "mobx-react";
import { RecaptchaDisclaimer } from "components/misc/contactUs/recaptchaDisclaimer";
import { PropertyOrUnitCtaFromExternalSource } from "properties/propertyPage/PropertyOrUnitCtaFromExternalSource";
import { SIGN_UP_FROM, SIGN_UP_TYPE } from "users/_models/UserMdl";
import { Broker } from "properties/propertyPage/contact-form/broker";
import { ContactFormPhone } from "properties/propertyPage/contact-form/ContactFormPhone";
import { Subscription } from "properties/propertyPage/contact-form/subscription";

export type TAskForVisitOrInfoForm = {
    name: string;
    phone: string;
    email: string;
    date: string;
    message?: string;
    propertyId?: string;
    unitId?: string;
    lang?: string;
    notification?: boolean;
    signUpType?: SIGN_UP_TYPE;
    signUpFrom?: SIGN_UP_FROM;
    firstName?: string;
    lastName?: string;
};

const PropertyOrUnitContactForm = observer(() => {
    const propertyStore = usePropertyStore();
    const [hasAgreedNotification, setHasAgreedNotification] = useState(false);
    const { t } = useTranslation();

    if (
        propertyStore.property.source?.platform === "centris" ||
        propertyStore.property.source?.platform === "duproprio"
    ) {
        return <PropertyOrUnitCtaFromExternalSource link={propertyStore.property.localized.propertyLink} />;
    }

    const form = useForm<TAskForVisitOrInfoForm>({
        mode: "onBlur",
        defaultValues: {
            firstName: userStore.isLogged ? userStore.user?.firstName : "",
            lastName: userStore.isLogged ? userStore.user?.lastName : "",
            name: userStore.isLogged ? userStore.user?.firstName + " " + userStore.user?.lastName : "",
            phone: userStore.isLogged ? userStore.user?.phone : "",
            email: userStore.isLogged ? userStore.user?.email : "",
            message: t("propertyPage.contact-form.message") + propertyStore.property.localized.title + ".",
        },
    });

    const appointingFormIsDisabled =
        propertyStore.property.developer?.isAppointingFormDisabled || propertyStore.property.isAppointingFormDisabled;
    if (appointingFormIsDisabled && propertyStore.property.isContactFormDisabled) return null;

    const hasPhoneAndIsNotBrokerSalesTypeProperty =
        propertyStore.property.contactPhone &&
        propertyStore.property.salesType !== PROPERTY_SALES_TYPE.BROKER &&
        propertyStore.property.salesType !== PROPERTY_SALES_TYPE.PPS;

    return (
        <FormProvider {...form}>
            <div className={styles.container}>
                {propertyStore.property.salesType === PROPERTY_SALES_TYPE.BROKER ? <Broker /> : <Subscription />}
                <div className={"mt_25"}>
                    <CheckboxAgree checked={hasAgreedNotification} onChange={setHasAgreedNotification} />
                </div>
                <div className={"mt_25"}>
                    <PropertyOrUnitContactFormBtn hasAgreedNotification={hasAgreedNotification} />
                </div>
                <div className="mt_25">
                    <RecaptchaDisclaimer />
                </div>
            </div>
            {hasPhoneAndIsNotBrokerSalesTypeProperty && <ContactFormPhone />}
        </FormProvider>
    );
});

export default PropertyOrUnitContactForm;
