import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { InputBlock } from "_common/ui/forms/InputBlock";
import { Input } from "_common/ui/forms/Input";
import { Grid } from "@material-ui/core";
import { TextArea } from "_common/ui/forms/TextArea";
import { ContactFormBtn } from "components/misc/contactUs/ContactFormBtn";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_TYPE } from "components/misc/contactUs/_stores/notifierStore";

export type TContactPromoteForm = {
    name: string;
    company: string;
    email: string;
    phone: string;
    text?: string;
    lang: string;
};

type Props = {
    type: NOTIFICATION_TYPE;
    isTextArea?: boolean;
    onSuccess?: () => void;
};

export function PromoteForm(props: Props) {
    const { t } = useTranslation();
    const form = useForm<TContactPromoteForm>({
        defaultValues: {
            name: "",
            company: "",
            email: "",
            phone: "",
            text: "",
        },
    });

    return (
        <FormProvider {...form}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={props.isTextArea ? 4 : 12}>
                    <InputBlock label={t("words.name")} required>
                        <Controller
                            as={Input}
                            name="name"
                            control={form.control}
                            error={form.errors.name}
                            rules={{ required: true }}
                        />
                    </InputBlock>
                    <InputBlock label={t("words.companyName")} required>
                        <Controller
                            as={Input}
                            name="company"
                            control={form.control}
                            error={form.errors.company}
                            rules={{ required: true }}
                        />
                    </InputBlock>
                    <InputBlock label={t("words.email")} required>
                        <Controller
                            as={Input}
                            name="email"
                            control={form.control}
                            error={form.errors.email}
                            rules={{
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                                    message: t("errors.forms.emailInvalid"),
                                },
                            }}
                        />
                    </InputBlock>
                    <InputBlock label={t("words.phone")} required>
                        <Controller
                            as={Input}
                            name="phone"
                            control={form.control}
                            error={form.errors.phone}
                            rules={{ required: true }}
                        />
                    </InputBlock>
                </Grid>
                <Grid item xs={12} md={props.isTextArea ? 8 : 12}>
                    {props.isTextArea && (
                        <InputBlock label={t("words.message")}>
                            <Controller as={TextArea} name="text" control={form.control} rows={10} />
                        </InputBlock>
                    )}

                    <div className={"flex_center_center"}>
                        <ContactFormBtn
                            id={"advertiseContactFormBtn"}
                            type={props.type}
                            successToast
                            onSuccess={() => {
                                props.onSuccess ? props.onSuccess() : "";
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </FormProvider>
    );
}
