import { TMapCoordinates } from "maps/Map";
import { CA_CITIES } from "_common/regions/regions";
import _ from "lodash";
import { PropertiesStore } from "properties/_stores/propertiesStore";
import { ListStore } from "_common/list/ListStore";
import { PROPERTY_PURPOSE, PROPERTY_TYPE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import * as H from "history";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { URLS } from "_configs/URLS";
import { getUrlSearchFromFilters, updateSearchFilter } from "properties/searchBar/filters/_utils/filtersUtils";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { getMapCoordinates } from "maps/MapRender";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import i18next from "i18next";
import { GEO_ZONE_TYPE, TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";
import { PLACEMENT_PROPERTY_TYPE } from "placements/_models/PlacementsMdl";
import { PROPERTY_FEATURES, PROPERTY_OPTION } from "_configs/propertyConfig";
import { TFilter } from "admin/_common/filters/TFilter";

export const DEFAULT_CITY = "Montreal";
const { lat, lng } = CA_CITIES.find((city) => city.name === DEFAULT_CITY) ?? {
    lat: 45.5089,
    lng: -73.5617,
};
export const DEFAULT_LOCATION = { lat, lng };
export const DEFAULT_ZOOM = 6;
export const DEFAULT_RADIUS = 1100;
export const EARTH_RADIUS = 6378;
export const EARTH_CIRCUMFERENCE = 40000;
export const DEFAULT_MAP_COORDINATES: TMapCoordinates = {
    n: 51.13339373963635,
    e: -63.454278125,
    s: 39.26059892859612,
    w: -83.9767390625,
};

export const DEFAULT_MAP_COORDINATES_ZONE = [
    [DEFAULT_MAP_COORDINATES.w, DEFAULT_MAP_COORDINATES.n],
    [DEFAULT_MAP_COORDINATES.e, DEFAULT_MAP_COORDINATES.n],
    [DEFAULT_MAP_COORDINATES.e, DEFAULT_MAP_COORDINATES.s],
    [DEFAULT_MAP_COORDINATES.w, DEFAULT_MAP_COORDINATES.s],
    [DEFAULT_MAP_COORDINATES.w, DEFAULT_MAP_COORDINATES.n],
];

export const PARAMS_ZOOM_PREFIX = "z_";
export const PARAMS_PAGE_PREFIX = "p_";
export const PARAMS_NORTH_PREFIX = "n_";

export function getMapCoordinatesFromParams(coordinates: string[]) {
    const mapCoordinates = DEFAULT_MAP_COORDINATES;
    if (coordinates && coordinates.length === 4) {
        mapCoordinates.n = parseFloat(coordinates[0].slice(2));
        mapCoordinates.e = parseFloat(coordinates[1].slice(2));
        mapCoordinates.s = parseFloat(coordinates[2].slice(2));
        mapCoordinates.w = parseFloat(coordinates[3].slice(2));
    }
    return mapCoordinates;
}

export function getMapZoneCoordinatesFromMapCoordinates(coordinates: TMapCoordinates) {
    return [
        [coordinates.w, coordinates.n],
        [coordinates.e, coordinates.n],
        [coordinates.e, coordinates.s],
        [coordinates.w, coordinates.s],
        [coordinates.w, coordinates.n],
    ];
}

export function getNeswCoordinatesFromMapCoordinates(coordinates: TMapCoordinates) {
    return `n_${coordinates.n},e_${coordinates.e},s_${coordinates.s},w_${coordinates.w}`;
}

export function getPlacementTypeOfSrps(listStore: ListStore<TPropertyListingMdl>): PLACEMENT_PROPERTY_TYPE {
    const isCommercial =
        listStore.filters.find((filter) => filter.id === "type")?.value?.[0] === PROPERTY_TYPE.commercial;
    if (isCommercial) {
        return PLACEMENT_PROPERTY_TYPE.COMMERCIAL_RENT;
    } else if (listStore.listId === PROPERTY_PURPOSE.RENT) {
        return PLACEMENT_PROPERTY_TYPE.RESIDENTIAL_RENT;
    }
    return PLACEMENT_PROPERTY_TYPE.RESIDENTIAL_SALE;
}

function buildUrlParams(
    listStore: ListStore<TPropertyListingMdl>,
    propertiesStore: PropertiesStore,
    filterPropertyType?: TFilter,
    filterPropertySubType?: TFilter,
    isGarageOnParam?: boolean,
    geoZone?: TGeoZoneMdl,
) {
    let p1, p2, p3, p4, p5, p6, p7, p8;
    if (!geoZone) {
        const northEastSouthWest = getNeswCoordinatesFromMapCoordinates(propertiesStore.searchParams.mapCoordinates);
        if (filterPropertyType) {
            p1 = reformatStringForUrls(i18next.t("property.typesUrl." + filterPropertyType.value[0])).toLowerCase();
            if (filterPropertySubType) {
                p2 = reformatStringForUrls(i18next.t("property.subTypesUrl." + filterPropertySubType.value[0]));
                if (isGarageOnParam) {
                    p3 = reformatStringForUrls(i18next.t(`property.featuresUrls.${PROPERTY_OPTION.indoorParking}`));
                    if (listStore.selectedPage > 1) {
                        p4 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                        p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                    } else {
                        p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                    }
                } else {
                    p3 = northEastSouthWest;
                    if (listStore.selectedPage > 1) {
                        p4 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                        p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                    }
                    p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            } else if (isGarageOnParam) {
                p2 = reformatStringForUrls(i18next.t(`property.featuresUrls.${PROPERTY_OPTION.indoorParking}`));
                if (listStore.selectedPage > 1) {
                    p3 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p3 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            } else {
                p2 = northEastSouthWest;
                if (listStore.selectedPage > 1) {
                    p3 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p3 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            }
        } else {
            p1 = northEastSouthWest;
            if (listStore.selectedPage > 1) {
                p2 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                p3 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
            } else {
                p2 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
            }
        }
    } else if (geoZone) {
        if (geoZone.type === GEO_ZONE_TYPE.CITY) {
            p2 = reformatStringForUrls(geoZone.address.city ?? propertiesStore.addressParams.city ?? DEFAULT_CITY);
            if (filterPropertyType) {
                p3 = reformatStringForUrls(i18next.t("property.typesUrl." + filterPropertyType.value[0]));
                if (filterPropertySubType) {
                    p4 = reformatStringForUrls(i18next.t("property.subTypesUrl." + filterPropertySubType.value[0]));
                    if (isGarageOnParam) {
                        p5 = reformatStringForUrls(i18next.t(`property.featuresUrls.${PROPERTY_OPTION.indoorParking}`));
                        if (listStore.selectedPage > 1) {
                            p6 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                            p7 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                        } else {
                            p6 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                        }
                    } else if (listStore.selectedPage > 1) {
                        p5 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                        p6 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                    } else {
                        p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                    }
                } else {
                    if (isGarageOnParam) {
                        p4 = reformatStringForUrls(i18next.t(`property.featuresUrls.${PROPERTY_OPTION.indoorParking}`));
                        if (listStore.selectedPage > 1) {
                            p5 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                            p6 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                        } else {
                            p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                        }
                    } else if (listStore.selectedPage > 1) {
                        p4 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                        p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                    } else {
                        p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                    }
                }
            } else {
                if (listStore.selectedPage > 1) {
                    p3 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p3 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            }
        }

        if (geoZone.type === GEO_ZONE_TYPE.NEIGHBORHOOD) {
            p2 = reformatStringForUrls(geoZone.address.city ?? propertiesStore.addressParams.city ?? DEFAULT_CITY);
            p3 = reformatStringForUrls(geoZone.address.neighbourhood);
            if (filterPropertyType) {
                p4 = reformatStringForUrls(i18next.t("property.typesUrl." + filterPropertyType.value[0]));
                if (filterPropertySubType) {
                    p5 = reformatStringForUrls(i18next.t(`property.subTypesUrl.${filterPropertySubType.value[0]}`));
                    if (isGarageOnParam) {
                        p6 = reformatStringForUrls(i18next.t(`property.featuresUrls.${PROPERTY_OPTION.indoorParking}`));
                        if (listStore.selectedPage > 1) {
                            p7 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                            p8 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                        } else {
                            p7 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                        }
                    } else if (listStore.selectedPage > 1) {
                        p6 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                        p7 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                    } else {
                        p6 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                    }
                } else {
                    if (isGarageOnParam) {
                        p5 = reformatStringForUrls(i18next.t(`property.featuresUrls.${PROPERTY_OPTION.indoorParking}`));
                        if (listStore.selectedPage > 1) {
                            p6 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                            p7 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                        } else {
                            p6 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                        }
                    } else if (listStore.selectedPage > 1) {
                        p5 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                        p6 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                    } else {
                        p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                    }
                }
            } else {
                if (listStore.selectedPage > 1) {
                    p4 = PARAMS_PAGE_PREFIX + listStore.selectedPage;
                    p5 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                } else {
                    p4 = PARAMS_ZOOM_PREFIX + propertiesStore.searchParams.zoom;
                }
            }
        }
        p1 = geoZone.address.province?.trim().toLowerCase().replace(/ /g, "_");
    }

    return { p1, p2, p3, p4, p5, p6, p7, p8 };
}

export function getIsGarageConcernedOnFilters(filters: TFilter[]) {
    const configGarage = PROPERTY_FEATURES.find((feature) => feature.key === PROPERTY_OPTION.indoorParking);
    const filterGarage = filters.find((filter) => filter.id === `features.${PROPERTY_OPTION.indoorParking}`);
    if (!configGarage || !filterGarage) return false;
    const filterPropertyType = filters.find((filter) => filter.id === "type");
    if (!filterPropertyType?.value.length) return false;
    const filterPropertySubType = filters.find((filter) => filter.id === "subType");
    if (filterPropertySubType && configGarage.types?.includes(filterPropertySubType.value[0])) return true;
    if (!filterPropertySubType && filterPropertyType && configGarage.types?.includes(filterPropertyType.value[0])) {
        return true;
    }
    return false;
}

export const fetchSearchProperties = _.debounce(
    async (
        propertiesStore: PropertiesStore,
        listStore: ListStore<TPropertyListingMdl>,
        history: H.History,
        forceReload = false,
        deepListOnly = false,
    ) => {
        propertiesStore.searchParams.mapCoordinates = getMapCoordinates();
        propertiesStore.resetItems();

        const filtersWithNewMapCoordinates = [
            ...listStore.filters.filter((filter) => filter.id !== "location"),
            {
                id: "location",
                value: {
                    geoZoneCoordinates: propertiesStore.searchParams.mapCoordinates.n
                        ? [
                              [
                                  propertiesStore.searchParams.mapCoordinates.w,
                                  propertiesStore.searchParams.mapCoordinates.n,
                              ],
                              [
                                  propertiesStore.searchParams.mapCoordinates.e,
                                  propertiesStore.searchParams.mapCoordinates.n,
                              ],
                              [
                                  propertiesStore.searchParams.mapCoordinates.e,
                                  propertiesStore.searchParams.mapCoordinates.s,
                              ],
                              [
                                  propertiesStore.searchParams.mapCoordinates.w,
                                  propertiesStore.searchParams.mapCoordinates.s,
                              ],
                              [
                                  propertiesStore.searchParams.mapCoordinates.w,
                                  propertiesStore.searchParams.mapCoordinates.n,
                              ],
                          ]
                        : DEFAULT_MAP_COORDINATES_ZONE,
                },
                type: TFilterType.ZONE,
            },
        ];

        void propertiesStore.getUnitsCount(filtersWithNewMapCoordinates);
        propertiesStore.deepList(filtersWithNewMapCoordinates);

        if (deepListOnly) return;

        const filterPropertyType = listStore.filters.find((filter) => filter.id === "type");
        const filterPropertySubType = listStore.filters.find((filter) => filter.id === "subType");
        const isGarageOnParams = getIsGarageConcernedOnFilters(listStore.filters);
        if (!geoZonesStore.geoZone) {
            const url = getUrlSearchFromFilters(listStore.filters);
            const { p1, p2, p3, p4, p5 } = buildUrlParams(
                listStore,
                propertiesStore,
                filterPropertyType,
                filterPropertySubType,
                isGarageOnParams,
            );

            await updateSearchFilter(listStore, [
                {
                    id: "location",
                    value: {
                        geoZoneCoordinates: [
                            [
                                propertiesStore.searchParams.mapCoordinates.w,
                                propertiesStore.searchParams.mapCoordinates.n,
                            ],
                            [
                                propertiesStore.searchParams.mapCoordinates.e,
                                propertiesStore.searchParams.mapCoordinates.n,
                            ],
                            [
                                propertiesStore.searchParams.mapCoordinates.e,
                                propertiesStore.searchParams.mapCoordinates.s,
                            ],
                            [
                                propertiesStore.searchParams.mapCoordinates.w,
                                propertiesStore.searchParams.mapCoordinates.s,
                            ],
                            [
                                propertiesStore.searchParams.mapCoordinates.w,
                                propertiesStore.searchParams.mapCoordinates.n,
                            ],
                        ],
                    },
                    type: TFilterType.ZONE,
                },
            ]);
            history.push(
                URLS[propertiesStore.purpose === PROPERTY_PURPOSE.BUY ? "buy" : "rent"](undefined, p1, p2, p3, p4, p5) +
                    (url ? "?" + url : ""),
            );
        } else {
            if (
                geoZonesStore.geoZone._id !==
                    listStore.filters.find((filter) => filter.id === "location")?.value?.geoZoneId ||
                forceReload
            ) {
                await updateSearchFilter(listStore, [
                    {
                        id: "location",
                        value: {
                            geoZoneId: geoZonesStore.geoZone._id,
                        },
                        type: TFilterType.ZONE,
                    },
                ]);
            }
            void propertiesStore.getUnitsCount(listStore.filters);
            const url = getUrlSearchFromFilters(listStore.filters);

            const { p1, p2, p3, p4, p5, p6, p7 } = buildUrlParams(
                listStore,
                propertiesStore,
                filterPropertyType,
                filterPropertySubType,
                isGarageOnParams,
                geoZonesStore.geoZone,
            );
            history.push(
                URLS[propertiesStore.purpose === PROPERTY_PURPOSE.BUY ? "buy" : "rent"](
                    undefined,
                    p1,
                    p2,
                    p3,
                    p4,
                    p5,
                    p6,
                    p7,
                ) + (url ? "?" + url : ""),
            );
        }
    },
    1000,
);

export function getUrlWithPagination(url: string, page: number) {
    // il y a pas de page
    //     si pas de zoom
    //         alors p_1
    //     sinon p_1/z_1
    // si page
    //     si pas de zoom
    //         alors p_n+1
    //     sinon p_n+1/z_n

    if (/\/p_[0-9]+/.test(url)) {
        return url.replace(/p_[0-9]+/, PARAMS_PAGE_PREFIX + page);
    } else if (/\/z_[0-9]+/.test(url)) {
        const zoom = new RegExp(/z_[0-9]+/).exec(url)?.[0];
        return url.replace(/z_[0-9]+/, PARAMS_PAGE_PREFIX + page) + "/" + zoom;
    }

    return url + "/" + PARAMS_PAGE_PREFIX + page;
}
