import styles from "properties/propertyPage/_css/propertyOrUnitCta.module.css";
import clsx from "clsx";
import { analyticsUtils } from "_common/_utils/analyticsUtils";
import { UNIT_EVENT } from "units/_models/UnitMdl";
import { PROPERTY_EVENT } from "properties/_models/PropertyMdl";
import { CallCtaIcon } from "properties/propertyPage/_components/CallCtaIcon";
import React, { useState } from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { useUnitStore } from "units/UnitContext";
import { useTranslation } from "react-i18next";

export const ContactFormPhone = () => {
    const { t } = useTranslation();
    const propertyStore = usePropertyStore();
    const unitStore = useUnitStore();
    const [phoneIsVisible, setPhoneIsVisible] = useState(false);
    const hiddenPhone = propertyStore.property?.contactPhone.toString().slice(0, -4) + "XXXX";

    return (
        <div className={styles.phoneContainer}>
            <div className={styles.orContainer}>
                <div className={styles.or}>{t("words.OR")}</div>
            </div>
            <div className={clsx("flex_row_center mb_5 justifyContent_center")}>
                <a
                    href={`tel:${propertyStore.property.contactPhone}`}
                    className={clsx("flex_row_center", styles.text, styles.phoneTextContainer)}
                    onClick={() => {
                        if (phoneIsVisible) return;
                        setPhoneIsVisible(!phoneIsVisible);

                        Object.keys(unitStore).length > 0
                            ? analyticsUtils.tracksEventOfItem(UNIT_EVENT.BUILDER_PHONE, unitStore.unit._id)
                            : analyticsUtils.tracksEventOfItem(
                                  PROPERTY_EVENT.BUILDER_PHONE,
                                  propertyStore.property._id,
                              );
                    }}
                >
                    <CallCtaIcon />
                    <div className={"flex_row_center mt_3"}>
                        <div className={"text_bold ml_5"}>{t("words.call")}</div>
                        <div className={clsx("ml_5")}>
                            {!phoneIsVisible ? hiddenPhone : propertyStore.property.contactPhone}
                        </div>
                    </div>
                </a>
            </div>
            {phoneIsVisible && (
                <div className={styles.mentionContainer}>
                    <div className={styles.mentionText}>{t("propertyPage.contact-form.contactMention")}</div>
                </div>
            )}
        </div>
    );
};
