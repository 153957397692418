import { BaseResourceStore, DEFAULT_PAGE_SIZE, TInitialState } from "_common/resources/BaseResourceStore";
import {
    PROPERTY_PURPOSE,
    PROPERTY_TYPE,
    TPropertyDashboardListingMdl,
    TPropertyListingMdl,
    TPropertyMdl,
} from "properties/_models/PropertyMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { fetchUtils, TFilesData } from "_common/_utils/fetchUtils";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import dayjs from "dayjs";
import i18next from "i18next";
import { action, observable } from "mobx";
import { LoadingStateMdl } from "_common/loaders/_models/LoadingStateMdl";
import {
    deleteItemInitialSate,
    getInitialStateValue,
    getResourceInitialStateValue,
    putPromiseResultInInitialState,
} from "_common/_utils/initialStateUtils";
import { ListStore } from "_common/list/ListStore";
import { TLang } from "_configs/sharedConfig";
import { TUnitListingMdl } from "units/_models/UnitMdl";
import { TGoogleLocation, TMapCoordinates } from "maps/Map";
import { DEFAULT_LOCATION, DEFAULT_MAP_COORDINATES, DEFAULT_ZOOM } from "_common/_utils/searchUtils";
import { TGeoZoneMdl } from "geoZones/_models/GeoZoneMdl";
import _ from "lodash";
import { WithRequiredProperty } from "_common/types/GenericTypes";

type TBtns = {
    [PROPERTY_PURPOSE.BUY]: {
        [PROPERTY_TYPE.house]: boolean;
        [PROPERTY_TYPE.condo]: boolean;
        [PROPERTY_TYPE.multiplex]: boolean;
        [PROPERTY_TYPE.senior]: boolean;
    };
    [PROPERTY_PURPOSE.RENT]: {
        [PROPERTY_TYPE.house]: boolean;
        [PROPERTY_TYPE.condo]: boolean;
        [PROPERTY_TYPE.multiplex]: boolean;
        [PROPERTY_TYPE.senior]: boolean;
    };
    neighborhoodBtns: {
        neighborhoodName: string;
        purpose: PROPERTY_PURPOSE;
        propertyType: PROPERTY_TYPE;
    }[];
};

const SHOWCASE_LIMIT = 7;
const FEATURED_PROPERTIES_LIMIT = 24;

export class PropertiesStore extends BaseResourceStore<TPropertyListingMdl> {
    purpose: PROPERTY_PURPOSE | undefined;

    @observable btnsState = new LoadingStateMdl<TBtns>();
    @observable btns: TBtns = {
        [PROPERTY_PURPOSE.BUY]: {
            [PROPERTY_TYPE.house]: false,
            [PROPERTY_TYPE.condo]: false,
            [PROPERTY_TYPE.multiplex]: false,
            [PROPERTY_TYPE.senior]: false,
        },
        [PROPERTY_PURPOSE.RENT]: {
            [PROPERTY_TYPE.house]: false,
            [PROPERTY_TYPE.condo]: false,
            [PROPERTY_TYPE.multiplex]: false,
            [PROPERTY_TYPE.senior]: false,
        },
        neighborhoodBtns: [],
    };

    @observable addressParams: {
        region: string | undefined;
        city: string | undefined;
        address: string | undefined;
        neighbourhood: string | undefined;
    } = {
        region: "",
        city: "",
        address: "",
        neighbourhood: "",
    };

    @observable searchParams: {
        autocomplete: google.maps.places.Autocomplete | null;
        location: TGoogleLocation;
        zoom: number;
        mapCoordinates: TMapCoordinates;
    } = {
        autocomplete: null,
        location: DEFAULT_LOCATION,
        zoom: DEFAULT_ZOOM,
        mapCoordinates: DEFAULT_MAP_COORDINATES,
    };

    @observable featuredPropertiesState: {
        RENT: LoadingStateMdl<TPropertyListingMdl[]>;
        BUY: LoadingStateMdl<TPropertyListingMdl[]>;
        BOTH: LoadingStateMdl<TPropertyListingMdl[]>;
    } = {
        RENT: new LoadingStateMdl(),
        BUY: new LoadingStateMdl(),
        BOTH: new LoadingStateMdl(),
    };
    @observable featuredProperties: {
        RENT: TPropertyListingMdl[];
        BUY: TPropertyListingMdl[];
        BOTH: TPropertyListingMdl[];
    } = {
        RENT: [],
        BUY: [],
        BOTH: [],
    };

    @observable showcasePropertiesState: LoadingStateMdl<TPropertyListingMdl[]> = new LoadingStateMdl();
    @observable showcaseProperties: TPropertyListingMdl[] = [];

    @observable nearPropertiesState: LoadingStateMdl<TPropertyListingMdl[]> = new LoadingStateMdl();
    @observable nearProperties: TPropertyListingMdl[] = [];

    @observable mapPropertiesSelected: TPropertyListingMdl | undefined = undefined;
    @observable propertiesSearchState: LoadingStateMdl<
        Pick<TPropertyListingMdl, "_id" | "localized">[]
    > = new LoadingStateMdl<Pick<TPropertyListingMdl, "_id" | "localized">[]>();
    @observable propertiesSearch: Pick<TPropertyListingMdl, "_id" | "localized">[] = [];

    @observable items: TPropertyListingMdl[] = [];
    @observable countUnits = 0;

    setMapPropertiesSelected = _.debounce((property?: TPropertyListingMdl) => {
        this.mapPropertiesSelected = property;
    }, 400);

    constructor(purpose?: PROPERTY_PURPOSE) {
        super("properties");
        if (purpose) {
            this.purpose = purpose;
            this.onInit();
        }
    }

    static listEndpoint(payload: {
        apiPath: string;
        offset: number;
        purpose: PROPERTY_PURPOSE;
        limit?: number;
        _listId?: string;
        sort?: { [key: string]: number };
        filters?: TFilter[];
        isShuffle: boolean;
    }) {
        const { apiPath, offset, limit, sort, filters, isShuffle, purpose } = payload;
        const _filters = filters ?? [];
        const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
        if (purpose && _filters.findIndex((filter) => filter.id === "purpose") < 0) {
            _filters.push({ id: "purpose", type: TFilterType.ENUM, value: purpose });
        }
        const filtersParam = _filters.length > 0 ? `&filters=${JSON.stringify(_filters)}` : "";
        return `${apiPath}/listing?offset=${offset}&limit=${limit}${sortParam}${filtersParam}&lang=${i18next.language}&browser=${__BROWSER__}&isShuffle=${isShuffle}`;
    }

    getListStore(
        listId = "default",
        pageSize: number = DEFAULT_PAGE_SIZE,
        initialFilters?: TFilter[],
        noInitialLoading: boolean | undefined = undefined,
        forceReload = false,
        initialSort?: { [p: string]: number },
        initialPage?: number,
    ) {
        super.getListStore(listId, pageSize, initialFilters, noInitialLoading, forceReload, initialSort, initialPage);
        return this.listsStores[listId];
    }

    @action setSearchParams(params: { [key in "autocomplete" | "location" | "zoom" | "mapCoordinates"]: any }) {
        this.searchParams = {
            ...this.searchParams,
            ...params,
        };
    }

    @action resetItems() {
        this.items = [];
    }

    @action setLocation(location?: TGoogleLocation) {
        if (location) this.searchParams.location = location;
        else this.searchParams.location = DEFAULT_LOCATION;
    }

    @action setAutocomplete(autocomplete: google.maps.places.Autocomplete) {
        this.searchParams.autocomplete = autocomplete;
    }

    putItemInCache(item: TPropertyListingMdl) {
        super.putItemInCache(item);
        const itemIdex = this.items.findIndex((_item) => _item._id === item._id);
        if (itemIdex === -1) this.items.push(item);
    }

    list(
        offset = 0,
        limit?: number,
        _listId?: string,
        sort?: { [key: string]: number },
        filters?: TFilter[],
        isShuffle = false,
    ) {
        const url = PropertiesStore.listEndpoint({
            apiPath: this.apiPath,
            offset,
            limit,
            _listId,
            sort,
            filters,
            isShuffle,
            purpose: this.purpose ?? PROPERTY_PURPOSE.BUY,
        });
        return fetchUtils
            .get<{ count: number; items: TPropertyListingMdl[] }>(url)
            .then(({ data: { count, items } }) => ({
                count,
                items: items.map((item) => {
                    const reformattedItem = this.reformatItem(item);
                    this.putItemInCache(reformattedItem);
                    return reformattedItem;
                }),
            }));
    }

    similarList(propertyType: PROPERTY_TYPE, propertyCity: string, filters?: TFilter[]) {
        if (!filters) filters = [];
        if (this.purpose && filters.findIndex((filter) => filter.id === "purpose") < 0) {
            filters.push({ id: "purpose", type: TFilterType.ENUM, value: this.purpose });
        }
        const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
        const url = `${this.apiPath}/similarListing/${propertyType}/${propertyCity}?${filtersParam}&lang=${i18next.language}&browser=${__BROWSER__}`;
        return fetchUtils
            .get<{ count: number; items: TPropertyListingMdl[] }>(url)
            .then(({ data: { count, items } }) => ({
                count,
                items: items.map((item) => {
                    const reformattedItem = this.reformatItem(item);
                    this.putItemInCache(reformattedItem);
                    return reformattedItem;
                }),
            }));
    }

    listForDeveloper(sort?: { [key: string]: number }, filters?: TFilter[], token?: string) {
        if (!filters) filters = [];
        if (this.purpose && filters.findIndex((filter) => filter.id === "purpose") < 0) {
            filters.push({ id: "purpose", type: TFilterType.ENUM, value: this.purpose });
        }
        const sortParam = sort ? `&sort=${JSON.stringify(sort)}` : "";
        const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
        const developerToken = token ? `&developertoken=${token}` : "";
        const url = `${this.apiPath}/dashboardListing?lang=${i18next.language}${developerToken}${sortParam}${filtersParam}`;
        return fetchUtils.get<TPropertyDashboardListingMdl[]>(url).then(({ data }) => {
            data.map((property) => {
                const reformattedItem = this.reformatItem(property);
                this.putItemInCache(reformattedItem);
                return reformattedItem;
            });
            return { count: data.length, items: data };
        });
    }

    deepList(filters?: TFilter[]) {
        if (!filters) filters = [];
        if (this.purpose && filters.findIndex((filter) => filter.id === "purpose") < 0) {
            filters.push({ id: "purpose", type: TFilterType.ENUM, value: this.purpose });
        }
        const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
        const url = `${this.apiPath}/deepListing?${filtersParam}&lang=${i18next.language}`;
        return fetchUtils
            .get<{ count: number; items: TPropertyListingMdl[] }>(url)
            .then(({ data: { count, items } }) => ({
                count,
                items: items.map((item) => {
                    const reformattedItem = this.reformatItem(item);
                    const itemIdex = this.items.findIndex((_item) => _item._id === item._id);
                    if (itemIdex === -1) this.items.push({ ...item, isPartial: true });
                    return reformattedItem;
                }),
            }));
    }

    // Le fetch appel une api externe payante donc on l'execute 1 seule fois
    fetchFeaturedProperties(limit = FEATURED_PROPERTIES_LIMIT, purpose: PROPERTY_PURPOSE | "BOTH" = "BOTH") {
        if (!this.featuredPropertiesState[purpose].isLoading && !this.featuredPropertiesState[purpose].isSucceeded) {
            this.featuredPropertiesState[purpose].startLoading();
            const limitParam = limit ? `limit=${JSON.stringify(limit)}` : "";
            const filters: TFilter[] = [];

            if (purpose !== "BOTH") {
                filters.push({
                    id: "purpose",
                    type: TFilterType.ENUM,
                    value: purpose,
                });
            }

            const filtersParam = `&filters=${JSON.stringify(filters)}`;
            const url = `${this.apiPath}/featuredProperties?${limitParam}${filtersParam}&lang=${i18next.language}`;
            return fetchUtils.get<{ count: number; items: TPropertyListingMdl[] }>(url).then(({ data: { items } }) => {
                const properties = items.map((item) => {
                    const reformattedItem = this.reformatItem(item);
                    this.putItemInCache(reformattedItem);
                    return reformattedItem;
                });
                this.featuredProperties[purpose] = properties;
                this.featuredPropertiesState[purpose].setSuccess(properties);
                return properties;
            });
        }
        return;
    }

    // IS_SUCCEEDED + PAS DE PUT_IN_INITIAL_STATE CAR
    // Les FeaturedProperties dependent de la position de l'utilisateur donc pas de preloaded state (cache)

    fetchFeaturedPropertiesSrps(
        limit = FEATURED_PROPERTIES_LIMIT,
        purpose: PROPERTY_PURPOSE | "BOTH" = "BOTH",
        geoZoneId?: string,
        propertyType?: PROPERTY_TYPE,
    ) {
        const limitParam = limit ? `limit=${JSON.stringify(limit)}` : "";

        const geoZoneQuery = geoZoneId ? `&geoZoneId=${geoZoneId}` : "";
        const purposeQuery = purpose ? `&purpose=${purpose}` : "";
        const propertyTypeQuery = propertyType ? `&propertyType=${propertyType}` : "";
        const url = `${this.apiPath}/featuredPropertiesSrps?${limitParam}&lang=${i18next.language}${geoZoneQuery}${purposeQuery}${propertyTypeQuery}`;

        return fetchUtils.get<{ count: number; items: TPropertyListingMdl[] }>(url).then(({ data: { items } }) => {
            return items.map((item) => {
                const reformattedItem = this.reformatItem(item);
                this.putItemInCache(reformattedItem);
                return reformattedItem;
            });
        });
    }

    fetchShowcaseProperties(limit = SHOWCASE_LIMIT, coordinates?: { lat: number; lng: number }, isAdmin = false) {
        if (!this.showcasePropertiesState.isLoading && !this.showcasePropertiesState.isSucceeded && __BROWSER__) {
            this.showcasePropertiesState.startLoading();

            const coordinatesParam = coordinates ? `&lng=${coordinates.lng}&lat=${coordinates.lat}` : "";
            const route = isAdmin ? "showcasePropertiesAdmin" : "showcaseProperties";
            const url = `${this.apiPath}/${route}?limit=${limit}&lang=${i18next.language}${coordinatesParam}`;
            return fetchUtils.get<{ count: number; items: TPropertyListingMdl[] }>(url).then(({ data: { items } }) => {
                const properties = items.map((item) => this.reformatItem(item));
                this.showcaseProperties = properties;
                this.showcasePropertiesState.setSuccess(properties);
                return properties;
            });
        }
        return;
    }

    fetchNearList(propertyTypes: PROPERTY_TYPE[], geoZoneId: string, limit = DEFAULT_PAGE_SIZE - 6) {
        if (!this.nearPropertiesState.isLoading) {
            this.nearPropertiesState.startLoading();
            const filters: TFilter[] = [];
            if (this.purpose && filters.findIndex((filter) => filter.id === "purpose") < 0) {
                filters.push({ id: "purpose", type: TFilterType.ENUM, value: this.purpose });
            }
            if (propertyTypes.length) {
                filters.push({ id: "type", type: TFilterType.IN, value: propertyTypes });
            }
            filters.push({ id: "location", type: TFilterType.NEAR_GEOZONE, value: geoZoneId });
            const filtersParam = filters.length > 0 ? `&filters=${JSON.stringify(filters)}` : "";
            const sortParams = `&sort=${JSON.stringify({})}`; // to avoid createdAt: -1 default sort
            const url = `${this.apiPath}/listing?limit=${limit}${filtersParam}${sortParams}&lang=${i18next.language}&browser=${__BROWSER__}&isShuffle=0`;
            const promise = fetchUtils
                .get<{ count: number; items: TPropertyListingMdl[] }>(url)
                .then(({ data: { items } }) => {
                    const properties = items.map((item) => {
                        const reformattedItem = this.reformatItem(item);
                        this.putItemInCache(reformattedItem);
                        return reformattedItem;
                    });
                    const itemIds = Object.keys(_.groupBy(this.items, "_id"));
                    this.nearProperties = properties.filter((property) => itemIds.includes(property._id));
                    this.nearPropertiesState.setSuccess(this.nearProperties);
                    return properties;
                });
            putPromiseResultInInitialState(
                "propertiesStore.nearProperties",
                promise.then((properties) => properties),
            );
        }
        return;
    }

    getByAliasUrl(urlAlias: string, lang: string = i18next.language, wantedLanguage = "") {
        if (!wantedLanguage) {
            for (const propertyId of Object.keys(this.cache)) {
                if (this.cache[propertyId]?.localized.urlAlias === urlAlias) {
                    return this.cache[propertyId];
                }
            }
        }
        const url = `${this.apiPath}/urlAlias/${urlAlias}/${lang}/${wantedLanguage}`;
        return fetchUtils.get<TPropertyListingMdl>(url);
    }

    setLocationFromSearch(geoZone: TGeoZoneMdl) {
        this.addressParams.city = geoZone.address.city;
        this.addressParams.region = geoZone.address.province;
        this.addressParams.neighbourhood = geoZone.address.neighbourhood;
        this.addressParams.address = geoZone.name;
    }

    isPropertiesPage404() {
        return getInitialStateValue("is404");
    }

    fetchPropertiesForSearch(search?: string) {
        if (!this.propertiesSearchState.isLoading && search) {
            this.propertiesSearchState.startLoading();
            const url = `${this.apiPath}/search?search=${search}&lang=${i18next.language}`;
            return fetchUtils.get<{ items: Pick<TPropertyListingMdl, "_id" | "localized">[] }>(url).then(({ data }) => {
                this.propertiesSearch = data.items;
                this.propertiesSearchState.setSuccess(data.items);
                return data;
            });
        }
    }

    editProperty(item: Partial<TPropertyMdl>, files?: TFilesData) {
        const url = this.apiPath + "/edit/" + item._id;
        const body = files ? fetchUtils.createBodyWithFiles(item, files) : item;

        return fetchUtils.post<TPropertyListingMdl>(url + "?lang=" + i18next.language, body, !!files);
    }

    async getUnitsCount(filters?: TFilter[]) {
        if (!filters) return null;
        const filtersParam = `?filters=${JSON.stringify(filters)}`;
        const url = `${this.apiPath}/counting${filtersParam}`;
        return fetchUtils
            .get<{ numberOfUnits: number }>(url)
            .then(({ data }) => {
                this.countUnits = data.numberOfUnits;
            })
            .catch((e) => console.error(e));
    }

    protected onReset() {
        super.onReset();
        this.items = [];
        this.btnsState = new LoadingStateMdl();
        this.featuredProperties = {
            RENT: [],
            BUY: [],
            BOTH: [],
        };
        this.featuredPropertiesState = {
            RENT: new LoadingStateMdl(),
            BUY: new LoadingStateMdl(),
            BOTH: new LoadingStateMdl(),
        };
        this.nearProperties = [];
        this.nearPropertiesState = new LoadingStateMdl();
        this.showcaseProperties = [];
        this.showcasePropertiesState = new LoadingStateMdl<TPropertyListingMdl[]>();
    }

    // @ts-expect-error
    protected reformatItem(item: TPropertyListingMdl | TPropertyDashboardListingMdl) {
        const formattedItem = { ...item };
        const formattedUnits: TUnitListingMdl[] = [];
        if ("en" in formattedItem.localized) {
            formattedItem.localized = formattedItem.localized[i18next.language as TLang];
            if (formattedItem.units) {
                for (const unit of formattedItem.units) {
                    const formattedUnit = { ...unit };
                    if ("en" in formattedUnit.localized) {
                        formattedUnit.localized = formattedUnit.localized[i18next.language as TLang];
                    }
                    formattedUnits.push(formattedUnit);
                }
            }
        }
        formattedItem.units = formattedUnits;
        if ((item as any).deliveryDates) {
            (formattedItem as any).deliveryDates = formattedItem.deliveryDates.map((date) => dayjs(date));
        }
        return super.reformatItem(formattedItem);
    }

    protected onInit(_fromRootCtor?: boolean) {
        super.onInit(_fromRootCtor);
        const initialState = getResourceInitialStateValue(this.purpose + this.name) as
            | TInitialState<TPropertyListingMdl>
            | undefined;

        if (initialState) {
            initialState.items.map((item) => {
                if (item) {
                    this.putItemInCache(this.reformatItem(item));
                }
            });
            for (const listId in initialState.list ?? {}) {
                const pages: { [offset: number]: TPropertyListingMdl[] } = {};
                for (const offset in initialState.list[listId].pages) {
                    // @ts-expect-error
                    pages[Number(offset)] = initialState.list[listId].pages[offset];
                }
                this.listsStores[listId] = new ListStore(
                    listId,
                    this,
                    {
                        count: initialState.list[listId].count,
                        pages,
                        // @ts-expect-error
                        page: initialState.list[listId].page,
                    },
                    undefined,
                    true,
                    initialState?.list[listId]?.initialFilters,
                );
            }
        }
        void this.getUnitsCount(initialState?.list[PROPERTY_PURPOSE.BUY]?.initialFilters);
        const initialCenterState = getInitialStateValue<{ center: TGoogleLocation; zoom: number }>("location");
        if (initialCenterState) {
            this.searchParams.location = initialCenterState.center;
            this.searchParams.zoom = initialCenterState.zoom;
        }

        const initialBtnsState = getInitialStateValue<TBtns>("propertiesStore.btns");
        if (initialBtnsState) {
            this.btns = initialBtnsState;
            this.btnsState.setSuccess(initialBtnsState);
        }

        const initialNearState = getInitialStateValue<TPropertyListingMdl[]>("propertiesStore.nearProperties");
        if (initialNearState) {
            this.nearProperties = initialNearState;
            this.nearPropertiesState.setSuccess(initialNearState);
            deleteItemInitialSate("propertiesStore.nearProperties");
        }

        const initialGeoZoneState = getResourceInitialStateValue("geoZone") as
            | TInitialState<WithRequiredProperty<TGeoZoneMdl, "_id">>
            | undefined;
        if (initialGeoZoneState) {
            if (initialGeoZoneState.items[0]) {
                this.addressParams = {
                    region: initialGeoZoneState.items[0].address.province,
                    city: initialGeoZoneState.items[0].address.city,
                    address: initialGeoZoneState.items[0].name,
                    neighbourhood: initialGeoZoneState.items[0].address?.neighbourhood,
                };
            }
        } else {
            const initialCenterState = getInitialStateValue<{
                address: {
                    region: string;
                    city: string;
                    neighbourhood: string;
                };
            }>("location");
            if (initialCenterState) {
                this.addressParams = {
                    region: initialCenterState.address?.region,
                    city: initialCenterState.address?.city,
                    address: initialCenterState.address?.region + " " + initialCenterState.address?.city,
                    neighbourhood: initialCenterState.address?.neighbourhood,
                };
            }
        }
    }
}

const propertiesStore = new PropertiesStore();
export { propertiesStore };
