import React from "react";
import clsx from "clsx";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { useTranslation } from "react-i18next";
import { usePropertiesStore } from "properties/listing/PropertiesPageContext";
import styles from "./_css/PropertiesCount.module.css";
import { observer } from "mobx-react";

export const PropertiesCount = observer(() => {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(propertiesStore.purpose);
    const { cityForMeta } = useSearchPageParams();
    const { t } = useTranslation();

    return (
        <div className={clsx("flex_row_center", styles.textResult)}>
            <span>
                {listStore.count} {t("words.results")}
            </span>
            {(geoZonesStore.geoZone?.address.neighbourhood ??
                geoZonesStore.geoZone?.address.city ??
                cityForMeta ??
                propertiesStore.addressParams.city) && (
                <span className={"hiddenMobile"}>
                    {(geoZonesStore.geoZone?.address.city || cityForMeta) && (
                        <>
                            <span className={"mh_5"}>{t("words.for")}</span>
                            <span className={"mr_5"}>
                                {geoZonesStore.geoZone?.address.neighbourhood ??
                                    geoZonesStore.geoZone?.address.city ??
                                    cityForMeta}
                            </span>
                        </>
                    )}
                </span>
            )}
            <span>
                / {""}
                {t("propertiesPage.listingUnitsMatched", {
                    number: Number(propertiesStore.countUnits),
                })}
            </span>
        </div>
    );
});
