import {
    PARKING_TYPE,
    PROPERTY_STATUS,
    TFeature,
    TPropertyListingMdl,
    TRangeNumber,
} from "properties/_models/PropertyMdl";
import { TMeta, TRootComponent } from "pages/_models/PageMdl";
import dayjs, { Dayjs } from "dayjs";
import { TImage } from "_common/types/baseTypes";

export enum UNIT_EVENT {
    VISITED = "units/visited",
    PRINTED = "units/printed",
    BUILDER_PHONE = "units/builderPhone",
    BUILDER_LINK = "units/builderLink",
    UPDATED = "units/updated",
}

export enum UNIT_TYPE {
    bedrooms = "bedrooms",
    penthouse = "penthouse",
    demi = "1/2",
    garden = "garden",
}

export interface TUnitLocalized {
    title: string;
    description: string;
    urlAlias: string;
    meta: TMeta;
    component: TRootComponent;
}

export interface TParking {
    type: PARKING_TYPE;
    number: number;
}

export type TUnitBaseMdl = {
    _id: string;

    sold: boolean;
    published: boolean;

    price: TRangeNumber;
    priceIsNotDisplayed: boolean;
    type: UNIT_TYPE;
    storage: boolean | number;
    parkings: TParking[];
    status: PROPERTY_STATUS;
    discount: boolean;
    discountText: string;
    features: TFeature;
    bathrooms: number;
    powderRooms: number;
    bedrooms: number;
    basementBedrooms: number;
    squareSurface: TRangeNumber;
    terraceSurface: TRangeNumber;
    landSurface: TRangeNumber;

    photos: TImage[];
    floorPlans: TImage[];
    nbOfLevels: number;

    visit3D: string;
    video: string;
    video2: string;

    position: number;
    deliveryDates: dayjs.Dayjs[];

    createdAt: Dayjs;
    updatedAt: Dayjs;
};

export type TUnitMdl = TUnitBaseMdl & {
    localized: {
        fr: TUnitLocalized;
        es: TUnitLocalized;
        en: TUnitLocalized;
        zh: TUnitLocalized;
    };
    property: string;
};

export type TUnitListingMdl = TUnitBaseMdl & {
    localized: TUnitLocalized;
    urlAlias: {
        [lang: string]: string;
    };
    property: TPropertyListingMdl;
};

export type TUnitDashboardListingMdl = TUnitListingMdl & {
    _localized: {
        fr: TUnitLocalized;
        es: TUnitLocalized;
        en: TUnitLocalized;
        zh: TUnitLocalized;
    };
    stats: {
        printed: {
            count: number;
            date: Date;
        };
        visited: {
            count: number;
            date: Date;
        };
        favorited: {
            count: number;
        };
    };
};

export function createDefaultUnit(propertyId: string): Omit<TUnitMdl, "_id"> {
    return {
        localized: {
            fr: {
                title: "",
                description: "",
                meta: { title: "", description: "" },
                urlAlias: "",
                component: {
                    id: "div",
                    props: {
                        style: { padding: 0 },
                        children: [{ id: "container", props: { style: {}, children: [] } }],
                    },
                },
            },
            es: {
                title: "",
                description: "",
                meta: { title: "", description: "" },
                urlAlias: "",
                component: {
                    id: "div",
                    props: {
                        style: { padding: 0 },
                        children: [{ id: "container", props: { style: {}, children: [] } }],
                    },
                },
            },
            en: {
                title: "",
                description: "",
                meta: { title: "", description: "" },
                urlAlias: "",
                component: {
                    id: "div",
                    props: {
                        style: { padding: 0 },
                        children: [{ id: "container", props: { style: {}, children: [] } }],
                    },
                },
            },
            zh: {
                title: "",
                description: "",
                meta: { title: "", description: "" },
                urlAlias: "",
                component: {
                    id: "div",
                    props: {
                        style: { padding: 0 },
                        children: [{ id: "container", props: { style: {}, children: [] } }],
                    },
                },
            },
        },

        sold: false,
        published: false,
        property: propertyId,

        price: { min: 0, max: 0 },
        priceIsNotDisplayed: false,
        type: UNIT_TYPE.demi,
        parkings: [],
        deliveryDates: [],
        storage: false,
        status: PROPERTY_STATUS.PROJECT,
        discount: false,
        discountText: "",
        features: {},
        bathrooms: 0,
        powderRooms: 0,
        bedrooms: 0,
        basementBedrooms: 0,
        squareSurface: { min: 0, max: 0 },
        terraceSurface: { min: 0, max: 0 },
        landSurface: { min: 0, max: 0 },
        nbOfLevels: 0,

        photos: [],
        floorPlans: [],

        visit3D: "",
        video: "",
        video2: "",

        position: 0,

        createdAt: dayjs(),
        updatedAt: dayjs(),
    };
}
