import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InputTextCta } from "properties/propertyPage/_components/InputTextCta";
import { TextAreaCta } from "properties/propertyPage/_components/TextareaCta";
import { TAskForVisitOrInfoForm } from "properties/propertyPage/contact-form/property-or-unit-contact-form";
import { useTranslation } from "react-i18next";
import { Title } from "properties/propertyPage/contact-form/title";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import "./_css/style.css";

export function Subscription() {
    const form = useFormContext<TAskForVisitOrInfoForm>();
    const { t } = useTranslation();
    const propertyStore = usePropertyStore();

    return (
        <>
            <Title
                label={t("propertyPage.contact-form.subscription.title", {
                    propertyName: propertyStore.property.localized.title,
                })}
            />
            <div>
                <div className={"mt_25"}>
                    <Controller
                        as={InputTextCta}
                        control={form.control}
                        style={{ borderColor: form.watch("email") ? "#004378" : "#7A7E8D" }}
                        placeholder={t("words.email")}
                        name={"email"}
                        rules={{
                            required: t<string>("errors.forms.required"),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                                message: t("errors.forms.emailInvalid"),
                            },
                        }}
                    />
                </div>
                <div className={"mt_25"}>
                    <Controller
                        as={InputTextCta}
                        style={{ borderColor: form.watch("name") ? "#004378" : "#7A7E8D" }}
                        control={form.control}
                        placeholder={t("words.firstAndLastName")}
                        name={"name"}
                    />
                </div>
                <div className={"mt_25"}>
                    <Controller
                        as={InputTextCta}
                        style={{ borderColor: form.watch("phone") ? "#004378" : "#7A7E8D" }}
                        control={form.control}
                        placeholder={t("words.phone")}
                        name={"phone"}
                        rules={{
                            pattern: {
                                value: /^\d{10}$/,
                                message: t("errors.forms.phoneInvalid"),
                            },
                        }}
                    />
                </div>

                <div className={"mt_25"}>
                    <Controller
                        id={"subscription-message"}
                        name={"message"}
                        as={TextAreaCta}
                        placeholder={"Message"}
                        style={{
                            borderColor: form.watch("message") ? "#004378" : "#7A7E8D",
                        }}
                        control={form.control}
                        rows={5}
                    />
                </div>
                <Controller as={<input type="hidden" />} name="firstName" control={form.control} />
                <Controller as={<input type="hidden" />} name="lastName" control={form.control} />
            </div>
        </>
    );
}
